import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import styled from '@emotion/styled'

const HeroImage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "visit.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1400px;
    margin: 0 auto;
    padding: 4em 1.5em;
    color: #f7f7f7;
    height: 100%;
  `

  const HeroTitle = styled.h1`
    font-family: "Roboto Slab", serif;
    font-size: 2em;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    margin: 1em 1em .5em;
    line-height: 110%;
    text-align: center;
    color: #fdf5c0;

    @media (min-width: 1024px) {
      font-size: 3em;
    }
  `

  const HeroSubtitle = styled.div`
    font-family: "Montserrat", serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    line-height: 120%;
    text-align: center;
    margin-bottom: 1em;
    font-size: 1.1em;

    @media (min-width: 575px) {
      width: 75%;
    }

    @media (min-width: 1024px) {
      font-size: 1.5em;
      width: 60%;
    }
  `

  const backgroundFluidImageStack = [
   data.placeholderImage.childImageSharp.fluid,
   `linear-gradient(rgba(0, 0, 0, 0.53), rgba(0, 0, 0, 0.53))`,
 ].reverse()

  return (
      <BackgroundImage
        fluid={backgroundFluidImageStack}
        style={{ zIndex: "-1", width: "auto", backgroundSize: "cover", backgroundPosition: "center"}}
      >
        <Wrapper>
          <HeroTitle>
            Preparing for the Market
          </HeroTitle>
          <HeroSubtitle>
            Everything you need to know&mdash;and bring&mdash;for a trip to the Fairview Farmers Market. Be prepared for possible inclimate weather and to take home a lot more than you planned!
          </HeroSubtitle>
        </Wrapper>
      </BackgroundImage>
  )
}

export default HeroImage
