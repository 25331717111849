import React from "react"
import styled from '@emotion/styled'
import Image from "./image.js"

import Check from "../../images/check.inline.svg"

const HeroImage = () => {

  const Wrapper = styled.div`
    width: 100%;
    border-top: 4px solid #540B0E;
    background: #f9f9f9;
  `

  const InnerWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 4em 1.5em;
    color: #545454;
    display: flex;
    flex-direction: row;
  `

  const Title = styled.h3`
    font-size: 2em;
    color: #F32B38;
    font-family: "Roboto Slab";
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    line-height: 115%;
    margin-bottom: .35em;

    @media (min-width: 1024px) {
      font-size: 2.5em;
      margin-bottom: .5em;
    }
  `

  const Subtitle = styled.div`
    font-family: "Roboto Slab";
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    font-size: 1.15em;
    margin-bottom: 1.2em;
  `

  const Left = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;

    p {
      font-family: "Roboto", sans-serif;
      font-size: 1.12em;
      margin-bottom: 1.25em;
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
    }

    ul {
      display: flex;
      flex-direction: column;
      margin-top: .5em;
      list-style-type: circle;
      margin-bottom: 4em;

      li {
        margin-left: 1em;
        font-family: "Roboto", sans-serif;
        font-size: 1.12em;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        }
      }
    }

    @media (min-width: 1024px) {
      padding-right: 4em;
    }
  `

  const Right = styled.div`
    display: none;

    @media (min-width: 1024px) {
      display: block;
      margin-top: 2em;
    }
  `

  const ImageWrapper = styled.div`
    width: 425px;
    height: auto;
  `
  const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
  `

  const Column = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: flex-start;
    font-family: "Roboto", sans-serif;
    font-size: 1.12em;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;

    svg {
      width: 18px;
      fill: #545454;
      margin-right: .5em;
      margin-top: 4px;

    }
  `
  const SubHeading = styled.div`
    font-family: "Roboto Slab";
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    font-size: 1.25em;
    margin-bottom: .25em;
  `
  return (
        <Wrapper>
          <InnerWrapper>
            <Left>
              <Title>COVID-19 Safety Update</Title>
              <Subtitle>Fairview Farmers Market has implemented new COVID-19 health and safety policies.</Subtitle>
              <ul>
                <li>Face masks are required for both vendors and patrons</li>
                <li>Please be mindful and practice social distancing</li>
                <li>No animals permitted (Except for service animals)</li>
                <li>Look with your eyes&mdash;not with your hands</li>
              </ul>
              <Title>What to Bring</Title>
              <Row>
                <Column>
                  <Check />Face mask
                </Column>
                <Column>
                  <Check />Gloves
                </Column>
                <Column>
                  <Check />Hand sanitizer
                </Column>
              </Row>
              <Row style={{marginBottom: "4em"}}>
                <Column>
                  <Check />Tote bag(s)
                </Column>
                <Column>
                  <Check />Cash
                </Column>
                <Column>
                  <Check />Credit or debit card
                </Column>
              </Row>
              <Title>How to Pay</Title>
              <SubHeading>
                Cash
              </SubHeading>
              <p>Please be kind to your vendors by bringing smaller bills and change when you visit the market.</p>
              <SubHeading>
                Credit and Debit Cards
              </SubHeading>
              <p>Not all market vendors accept credit cards. Because of this, Fairview Farmers Market utilizes credit or "gift" tokens. These tokens are blue and have a $5 value. The tokens may purchased at the Information Booth and are meant to be used as gifts or as a way for customers that do not have cash to use credit cards for their purchases. Credit/gift tokens may be used to purchase any items, including crafts.</p>
              <SubHeading>
                WIC & SFMNP Coupons
              </SubHeading>
              <p>All eligible Fairview Farmers Market vendors are accepting Woman, Infants and Children (WIC) coupons and Senior Farmers’ Market Nutrition Program (SFMNP) coupons as a form of payment.</p>
            </Left>
            <Right>
              <ImageWrapper>
                <Image />
              </ImageWrapper>
            </Right>
          </InnerWrapper>
        </Wrapper>
  )
}

export default HeroImage
