import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/visit/hero"
import Intro from "../components/visit/intro"


const Visit = () => (
  <Layout>
    <SEO title="Preparing for the Market" />
    <Hero />
    <Intro />
  </Layout>
)

export default Visit
